// Text
h1 {
	@extend .is-size-1; }

h2 {
	@extend .is-size-2; }

h3 {
	@extend .is-size-3; }

h4 {
	@extend .is-size-4; }

h5 {
	@extend .is-size-5; }

h6 {
	@extend .is-size-6; }

// Table
table {
	@extend .table;
	@extend .is-striped;
	@extend .is-fullwidth; }

// hr
hr {
	@extend .has-background-grey-lighter; }

// Extra
.is-paddingless-top {
	padding-top: 0rem; }

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden; }

.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
