.imgprotect-container {
	position: relative;
	left: 0;
	top: 0; }

.imgprotect-content {
	position: relative;
	left: 0;
	top: 0;
	z-index: 1; }

.imgprotect-protect {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	height: 100%; }
